var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.orderReturnVo.returnsReviewStatus * 1 === 2
        ? _c(
            "div",
            { staticClass: "flex-start-center flex-wrap mt-20" },
            [
              _c(
                "a-popconfirm",
                {
                  attrs: {
                    title: "确认同意退货申请么？",
                    "ok-text": "Yes",
                    "cancel-text": "No"
                  },
                  on: {
                    confirm: function($event) {
                      return _vm.handleConfirmReturnPriceAction(
                        _vm.orderReturnVo.returnsNo,
                        3
                      )
                    }
                  }
                },
                [
                  _c("a-button", { attrs: { type: "danger" } }, [
                    _vm._v("同意退货申请")
                  ])
                ],
                1
              ),
              _c("span", { staticClass: "color-red ml-20" }, [
                _vm._v(
                  "（替卖家同意退货，同意后，买家将看见退货地址，逆向状态将是等待买家退货）"
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.orderReturnVo.returnsReviewStatus * 1 === 2
        ? _c(
            "div",
            { staticClass: "flex-start-center flex-wrap mt-20" },
            [
              _c(
                "a-popconfirm",
                {
                  attrs: {
                    title: "确认拒绝退货申请么？",
                    "ok-text": "Yes",
                    "cancel-text": "No"
                  },
                  on: {
                    confirm: function($event) {
                      return _vm.handleConfirmReturnPriceAction(
                        _vm.orderReturnVo.returnsNo,
                        -1
                      )
                    }
                  }
                },
                [
                  _c("a-button", { attrs: { type: "danger" } }, [
                    _vm._v("拒绝退货申请")
                  ])
                ],
                1
              ),
              _c("span", { staticClass: "color-red ml-20" }, [
                _vm._v(
                  "（替卖家拒绝退货，拒绝后，买家将不能再次发起退货申请，谨慎操作！）"
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.orderProductInfoVo.status * 1 === 3
        ? _c(
            "div",
            { staticClass: "flex-start-center flex-wrap mt-20" },
            [
              _c(
                "a-popconfirm",
                {
                  attrs: {
                    title: "确认代替买家同意收货么？",
                    "ok-text": "Yes",
                    "cancel-text": "No"
                  },
                  on: {
                    confirm: function($event) {
                      return _vm.handleConfirmReturnPriceAction(
                        _vm.orderReturnVo.returnsNo,
                        10
                      )
                    }
                  }
                },
                [
                  _c("a-button", { attrs: { type: "danger" } }, [
                    _vm._v("替买家同意收货")
                  ])
                ],
                1
              ),
              _c("span", { staticClass: "color-red ml-20" }, [
                _vm._v("（保持之前的逻辑）")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.orderProductInfoVo.status * 1 === 3
        ? _c(
            "div",
            { staticClass: "dis-flex-start mt-20" },
            [
              _c(
                "a-popconfirm",
                {
                  attrs: {
                    title: "确认代替买家拒绝收货么？",
                    "ok-text": "Yes",
                    "cancel-text": "No"
                  },
                  on: {
                    confirm: function($event) {
                      return _vm.handleConfirmReturnPriceAction(
                        _vm.orderReturnVo.returnsNo,
                        11
                      )
                    }
                  }
                },
                [
                  _c("a-button", { attrs: { type: "danger" } }, [
                    _vm._v("替买家拒绝收货")
                  ])
                ],
                1
              ),
              _c("span", { staticClass: "color-red ml-20" }, [
                _vm._v("（保持之前的逻辑）")
              ])
            ],
            1
          )
        : _vm._e(),
      [1, 3, 4, 5].includes(_vm.orderReturnVo.returnsReviewStatus)
        ? _c(
            "div",
            { staticClass: "flex-start-center flex-wrap mt-20" },
            [
              _c(
                "a-popconfirm",
                {
                  attrs: {
                    title: "确认替卖家同意收货么？",
                    "ok-text": "Yes",
                    "cancel-text": "No"
                  },
                  on: {
                    confirm: function($event) {
                      return _vm.handleConfirmReturnPriceAction(
                        _vm.orderReturnVo.returnsNo,
                        6
                      )
                    }
                  }
                },
                [
                  _c("a-button", { attrs: { type: "danger" } }, [
                    _vm._v("替卖家同意收货")
                  ])
                ],
                1
              ),
              _c("span", { staticClass: "color-red ml-20" }, [
                _vm._v("（替卖家确认收货，确认后，退货完成，款项原路退给买家）")
              ])
            ],
            1
          )
        : _vm._e(),
      [1, 3, 4, 5].includes(_vm.orderReturnVo.returnsReviewStatus)
        ? _c(
            "div",
            { staticClass: "dis-flex-start mt-20" },
            [
              _c(
                "a-popconfirm",
                {
                  attrs: {
                    title: "确认替卖家拒绝确认收货？",
                    "ok-text": "Yes",
                    "cancel-text": "No"
                  },
                  on: {
                    confirm: function($event) {
                      return _vm.handleConfirmReturnPriceAction(
                        _vm.orderReturnVo.returnsNo,
                        7
                      )
                    }
                  }
                },
                [
                  _c("a-button", { attrs: { type: "danger" } }, [
                    _vm._v("替卖家拒绝确认收货")
                  ])
                ],
                1
              ),
              _c("span", { staticClass: "color-red ml-20" }, [
                _vm._v("（替卖家拒绝确认收货，拒绝后，订单将重回正向流程）")
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex-start-center flex-wrap mt-20" },
        [
          _c("a-textarea", {
            staticClass: "w-600 mr-20",
            attrs: { placeholder: "请输入备注买卖双方都可见", "auto-size": "" },
            model: {
              value: _vm.remarkText,
              callback: function($$v) {
                _vm.remarkText = $$v
              },
              expression: "remarkText"
            }
          })
        ],
        1
      ),
      _c("ConfirmActionPopup", {
        ref: "confirmActionPopupEl",
        on: { succsss: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
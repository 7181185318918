var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { width: 500, title: "查看举证/留言列表" },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "flex-start-center" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleAddVoucher }
                        },
                        [_vm._v("添加举证")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showPopup,
            callback: function($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup"
          }
        },
        [
          _vm.voucherList && _vm.voucherList.length
            ? _c(
                "div",
                { staticClass: "voucher-list" },
                _vm._l(_vm.voucherList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "voucher-list-item" },
                    [
                      _c("div", { staticClass: "flex-start-center" }, [
                        _c("div", { staticClass: "voucher-list-left" }, [
                          _vm._v(_vm._s(index + 1))
                        ]),
                        _c("div", { staticClass: "voucher-list-right" }, [
                          _c(
                            "div",
                            { staticClass: "font-weight-bold color-black" },
                            [
                              _vm._v(_vm._s(_vm.computedShowStepTitle(item))),
                              _c("span", { staticClass: "color-gray" }, [
                                _vm._v(_vm._s(item.createTime))
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "font-video-img" },
                            [
                              _vm._l(item.proofImageList, function(img, ind) {
                                return _c("img", {
                                  key: ind,
                                  staticClass: "w-50 h-50 mr-20",
                                  attrs: { src: img, alt: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleShowBigImg(
                                        item.proofImageList,
                                        ind
                                      )
                                    }
                                  }
                                })
                              }),
                              item.proofVideoList
                                ? _c(
                                    "a-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.handleGoToShowVideo(
                                            item.proofVideoList[0]
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("查看视频")]
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "mt-50" }, [
                        _vm._v(_vm._s(item.proofDesc))
                      ])
                    ]
                  )
                }),
                0
              )
            : _c("a-empty")
        ],
        1
      ),
      _c("ServiceUploadVoucherPopup", {
        ref: "serviceUploadVoucherPopupEl",
        on: {
          success: function($event) {
            return _vm.getVoucherList(_vm.orderId)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "重要提醒", width: 500 },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c("div", { staticClass: "popup-content" }, [
        _c("div", { staticClass: "color-red" }, [
          _vm._v("这么做可能会导致钱款直接退回，请谨慎操作！")
        ]),
        [-1, 7].includes(_vm.params.status)
          ? _c(
              "div",
              { staticClass: "mt-30" },
              [
                _c(
                  "a-checkbox",
                  {
                    attrs: {
                      checked: _vm.checkedUserCanTwoApplyReturnPriceStatus
                    },
                    on: {
                      change: function($event) {
                        return _vm.handleChangeCheckStatus($event)
                      }
                    }
                  },
                  [_vm._v(" 用户是否可以再次申请退款？ ")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: "修改退款运费" },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b({ ref: "ruleForm" }, "a-form-model", _vm.layout, false),
        [
          _c(
            "a-form-item",
            { attrs: { label: "物流费用" } },
            [
              _c("a-input", {
                staticClass: "w-200",
                attrs: {
                  allowClear: "",
                  type: "number",
                  placeholder: "物流费用"
                },
                model: {
                  value: _vm.params.logisticsFee,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "logisticsFee", $$v)
                  },
                  expression: "params.logisticsFee"
                }
              }),
              _c("div", { staticClass: "mt-5 color-red" }, [
                _vm._v("订单金额："),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("￥" + _vm._s(_vm.payOrderPrice))
                ]),
                _vm._v("【运费补偿不能大于订单金额】")
              ])
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "添加备注" } },
            [
              _c("a-input", {
                staticClass: "w-100-w",
                attrs: {
                  allowClear: "",
                  type: "textarea",
                  "auto-size": { minRows: 4, maxRows: 6 },
                  placeholder: "备注描述，可选"
                },
                model: {
                  value: _vm.params.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "remark", $$v)
                  },
                  expression: "params.remark"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <a-modal v-model="showPopup" title="重要提醒" :width="500" @cancel="handleCancel" @ok="handleConfirm">
    <div class="popup-content">
      <div class="color-red">这么做可能会导致钱款直接退回，请谨慎操作！</div>
      <div class="mt-30" v-if="[-1, 7].includes(params.status)">
        <a-checkbox :checked='checkedUserCanTwoApplyReturnPriceStatus' @change="handleChangeCheckStatus($event)">
          用户是否可以再次申请退款？
        </a-checkbox>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {postConfirmChangeButtonApi} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_apis"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      selectedCount: 0,
      params: {
        returnsNo: undefined,
        status: undefined,
        content: undefined,
        againApplyRefund: undefined,
      },
      checkedUserCanTwoApplyReturnPriceStatus: false,
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.params = data
      this.showPopup = true
    },
    /** 确认提交 */
    async handleConfirm() {
      this.$loading.show()
      this.params.againApplyRefund = this.checkedUserCanTwoApplyReturnPriceStatus ? 1 : 0
      const res = await postConfirmChangeButtonApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.success(res.message || '成功');
      this.$emit('success')
      this.handleCancel()
    },
    /** 改版check */
    handleChangeCheckStatus(e) {
      this.checkedUserCanTwoApplyReturnPriceStatus = e.target.checked
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        returnsNo: undefined,
        status: undefined,
        content: undefined,
        againApplyRefund: undefined,
      }
      this.checkedUserCanTwoApplyReturnPriceStatus = false
      this.showPopup = false
    },
  }
}
</script>
<style scoped lang="scss">
.active-img-item {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}
.front-back-img-list {
  width: 100%;
  .img-list {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .img-item {
      position: relative;
      width: 200px;
    }
  }
  .title {
    font-size: 24px;
    font-weight: 400;
    color: black;
    span {
      font-weight: bold;
    }
  }
}
.popup-content {
  width: 100%;
}
</style>
<template>
  <div>
    <div class="flex-start-center flex-wrap mt-20" v-if="orderReturnVo.returnsReviewStatus * 1 === 2">
      <a-popconfirm
          title="确认同意退货申请么？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleConfirmReturnPriceAction(orderReturnVo.returnsNo, 3)"
      >
        <a-button type='danger'>同意退货申请</a-button>
      </a-popconfirm>
      <span class="color-red ml-20">（替卖家同意退货，同意后，买家将看见退货地址，逆向状态将是等待买家退货）</span>
    </div>
    <div class="flex-start-center flex-wrap mt-20" v-if="orderReturnVo.returnsReviewStatus * 1 === 2">
      <a-popconfirm
          title="确认拒绝退货申请么？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleConfirmReturnPriceAction(orderReturnVo.returnsNo, -1)"
      >
        <a-button type='danger'>拒绝退货申请</a-button>
      </a-popconfirm>
      <span class="color-red ml-20">（替卖家拒绝退货，拒绝后，买家将不能再次发起退货申请，谨慎操作！）</span>
    </div>
<!-- 替买家同意收货   -->
    <div class="flex-start-center flex-wrap mt-20" v-if="orderProductInfoVo.status * 1 === 3">
      <a-popconfirm
          title="确认代替买家同意收货么？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleConfirmReturnPriceAction(orderReturnVo.returnsNo, 10)"
      >
        <a-button type='danger'>替买家同意收货</a-button>
      </a-popconfirm>
      <span class="color-red ml-20">（保持之前的逻辑）</span>
    </div>
<!--  替买家拒绝收货  -->
    <div class="dis-flex-start mt-20" v-if="orderProductInfoVo.status * 1 === 3">
      <a-popconfirm
          title="确认代替买家拒绝收货么？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleConfirmReturnPriceAction(orderReturnVo.returnsNo, 11)"
      >
        <a-button type='danger'>替买家拒绝收货</a-button>
      </a-popconfirm>
      <span class="color-red ml-20">（保持之前的逻辑）</span>
    </div>
<!--  替卖家同意收货  -->
    <div class="flex-start-center flex-wrap mt-20" v-if="[1, 3, 4, 5].includes(orderReturnVo.returnsReviewStatus)">
      <a-popconfirm
          title="确认替卖家同意收货么？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleConfirmReturnPriceAction(orderReturnVo.returnsNo, 6)"
      >
        <a-button type='danger'>替卖家同意收货</a-button>
      </a-popconfirm>
      <span class="color-red ml-20">（替卖家确认收货，确认后，退货完成，款项原路退给买家）</span>
    </div>
<!--  替卖家拒绝确认收货  -->
    <div class="dis-flex-start mt-20" v-if="[1, 3, 4, 5].includes(orderReturnVo.returnsReviewStatus)">
      <a-popconfirm
          title="确认替卖家拒绝确认收货？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleConfirmReturnPriceAction(orderReturnVo.returnsNo, 7)"
      >
        <a-button type='danger'>替卖家拒绝确认收货</a-button>
      </a-popconfirm>
      <span class="color-red ml-20">（替卖家拒绝确认收货，拒绝后，订单将重回正向流程）</span>
    </div>
    <div class="flex-start-center flex-wrap mt-20">
      <a-textarea v-model="remarkText" class="w-600 mr-20" placeholder="请输入备注买卖双方都可见" auto-size/>
    </div>
<!--  确定提示  -->
    <ConfirmActionPopup ref="confirmActionPopupEl" @succsss="handleSuccess" />
  </div>
</template>
<script>
import _orderDetailMixin from "@/views/cmsPage/dqPlatform/orderListAboutManage/_mixins/_orderDetailMixin"
import ConfirmActionPopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_components/ReturnOrderDetailPopup/_components/ReturnOrderActionInfo/_components/ConfirmActionPopup/index.vue"

export default {
  components: {ConfirmActionPopup},
  props: ['orderProductInfoVo', 'orderReturnVo'],
  mixins: [_orderDetailMixin],
  emits: ['success'],
  data() {
    return {
      remarkText: undefined, // 备注
    }
  },
  methods: {
    /** 确定操作 */
    async handleConfirmReturnPriceAction(returnOrderNo, typeValue) {
      if (!returnOrderNo) return this.$message.warning("缺少该参数returnsNo");
      if (!this.remarkText) return this.$message.warning("请输入操作说明");

      this.$refs.confirmActionPopupEl.show({
        returnsNo: returnOrderNo,
        status: typeValue,
        content: this.remarkText,
      })
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    }
  },
}
</script>
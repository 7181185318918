var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: "修改退款理由" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "flex-sp-bt-center" },
                [
                  _c("a-button", { on: { click: _vm.handleShowUserVoucher } }, [
                    _vm._v("查看双方举证/留言")
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex-end-center" },
                    [
                      _c("a-button", { on: { click: _vm.handleCancel } }, [
                        _vm._v("取消")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleConfirm }
                        },
                        [_vm._v(_vm._s(_vm.buttonText))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.params } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-item",
            { attrs: { label: "退款理由" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-200 mb-5",
                  attrs: {
                    allowClear: "",
                    showSearch: "",
                    placeholder: "退款理由"
                  },
                  model: {
                    value: _vm.params.returnsType,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "returnsType", $$v)
                    },
                    expression: "params.returnsType"
                  }
                },
                _vm._l(_vm.returnPriceReasonList, function(item, value) {
                  return _c(
                    "a-select-option",
                    { key: value, attrs: { value: value } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.params.returnsType === "physicaldiscrepancy"
            ? _c(
                "a-form-item",
                { attrs: { label: "是否继续交易" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-200 mb-5",
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        placeholder: "是否继续交易"
                      },
                      model: {
                        value: _vm.params.type,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "type", $$v)
                        },
                        expression: "params.type"
                      }
                    },
                    _vm._l(_vm.isContinueActionList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.params.type
            ? _c(
                "a-form-item",
                { attrs: { label: "物流运费" } },
                [
                  _c("a-input", {
                    staticClass: "w-200",
                    attrs: {
                      allowClear: "",
                      type: "number",
                      placeholder: "物流运费"
                    },
                    model: {
                      value: _vm.params.logisticsFee,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "logisticsFee", $$v)
                      },
                      expression: "params.logisticsFee"
                    }
                  }),
                  _c("div", { staticClass: "mt-5 color-red" }, [
                    _vm._v("订单金额："),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("￥" + _vm._s(_vm.payOrderPrice))
                    ]),
                    _vm._v("【运费补偿不能大于订单金额】")
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-item",
            { attrs: { label: "话术模版" } },
            [
              _c("a-input", {
                staticClass: "w-100-w",
                attrs: {
                  allowClear: "",
                  type: "textarea",
                  "auto-size": { minRows: 4, maxRows: 8 },
                  placeholder:
                    "此话术模版为售后判定结果的话术模版，买卖双方均可见，前期先客服人员自己填写"
                },
                model: {
                  value: _vm.params.returnReason,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "returnReason", $$v)
                  },
                  expression: "params.returnReason"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("ReturnOrderUserVoucherListPopup", {
        ref: "returnOrderUserVoucherListPopupEl"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("a-input", {
          staticClass: "w-200 mb-5",
          attrs: { allowClear: "", placeholder: "订单号" },
          model: {
            value: _vm.params.orderNo,
            callback: function($$v) {
              _vm.$set(_vm.params, "orderNo", $$v)
            },
            expression: "params.orderNo"
          }
        }),
        _c("a-input", {
          staticClass: "ml-5 w-200 mb-5",
          attrs: { allowClear: "", placeholder: "物流单号" },
          model: {
            value: _vm.params.expressNo,
            callback: function($$v) {
              _vm.$set(_vm.params, "expressNo", $$v)
            },
            expression: "params.expressNo"
          }
        }),
        _c("a-input", {
          staticClass: "ml-5 w-200 mb-5",
          attrs: { allowClear: "", placeholder: "退款单号" },
          model: {
            value: _vm.params.returnsNo,
            callback: function($$v) {
              _vm.$set(_vm.params, "returnsNo", $$v)
            },
            expression: "params.returnsNo"
          }
        }),
        _c("a-input", {
          staticClass: "ml-5 w-200 mb-5",
          attrs: { allowClear: "", placeholder: "买家/uId/昵称/店铺" },
          model: {
            value: _vm.params.buyerUserName,
            callback: function($$v) {
              _vm.$set(_vm.params, "buyerUserName", $$v)
            },
            expression: "params.buyerUserName"
          }
        }),
        _c("a-input", {
          staticClass: "ml-5 w-200 mb-5",
          attrs: { allowClear: "", placeholder: "卖家/uId/昵称/店铺" },
          model: {
            value: _vm.params.sellerUserName,
            callback: function($$v) {
              _vm.$set(_vm.params, "sellerUserName", $$v)
            },
            expression: "params.sellerUserName"
          }
        }),
        _c(
          "a-select",
          {
            staticClass: "ml-5 w-200 mb-5",
            attrs: { allowClear: "", showSearch: "", placeholder: "订单类型" },
            model: {
              value: _vm.params.orderKind,
              callback: function($$v) {
                _vm.$set(_vm.params, "orderKind", $$v)
              },
              expression: "params.orderKind"
            }
          },
          _vm._l(_vm.orderTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "ml-5 w-200 mb-5",
            attrs: { allowClear: "", showSearch: "", placeholder: "订单状态" },
            model: {
              value: _vm.params.status,
              callback: function($$v) {
                _vm.$set(_vm.params, "status", $$v)
              },
              expression: "params.status"
            }
          },
          _vm._l(_vm.orderStatusTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "ml-5 w-200 mb-5",
            attrs: { allowClear: "", showSearch: "", placeholder: "退款状态" },
            model: {
              value: _vm.params.returnsReviewStatus,
              callback: function($$v) {
                _vm.$set(_vm.params, "returnsReviewStatus", $$v)
              },
              expression: "params.returnsReviewStatus"
            }
          },
          _vm._l(_vm.returnPriceOrderStatusList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "ml-5 w-200 mb-5",
            attrs: { allowClear: "", showSearch: "", placeholder: "退款理由" },
            model: {
              value: _vm.params.returnsType,
              callback: function($$v) {
                _vm.$set(_vm.params, "returnsType", $$v)
              },
              expression: "params.returnsType"
            }
          },
          _vm._l(_vm.returnPriceReasonList, function(item, value) {
            return _c(
              "a-select-option",
              { key: value, attrs: { value: value } },
              [_vm._v(_vm._s(item))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "ml-5 w-200 mb-5",
            attrs: { allowClear: "", showSearch: "", placeholder: "客服介入" },
            model: {
              value: _vm.params.isServiceIntervene,
              callback: function($$v) {
                _vm.$set(_vm.params, "isServiceIntervene", $$v)
              },
              expression: "params.isServiceIntervene"
            }
          },
          _vm._l(_vm.serviceJoinTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        false
          ? _c(
              "a-select",
              {
                staticClass: "ml-5 w-200 mb-5",
                attrs: {
                  allowClear: "",
                  showSearch: "",
                  placeholder: "处理状态"
                },
                model: {
                  value: _vm.params.markStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "markStatus", $$v)
                  },
                  expression: "params.markStatus"
                }
              },
              _vm._l(_vm.serviceActionOverStatusList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.value, attrs: { value: item.value } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            )
          : _vm._e(),
        _c(
          "a-button",
          {
            staticClass: "mb-5",
            attrs: { type: "primary" },
            on: { click: _vm.search }
          },
          [_vm._v("搜索")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-content" },
      [
        _c("TableList", {
          attrs: { list: _vm.data, pagination: _vm.pagination },
          on: { changePage: _vm.handleChangePage, success: _vm.getList }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
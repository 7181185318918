<template>
  <a-modal v-model="showPopup" :width='500' title="无法交付" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model ref="ruleForm" v-bind="layout">
      <a-form-item label="添加备注">
        <a-input
            allowClear
            v-model="params.returnReason"
            type="textarea"
            :auto-size="{ minRows: 4, maxRows: 6 }"
            class="w-100-w"
            placeholder='备注描述，可选'
        ></a-input>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {
  postSellerNotCanSendProductApi
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_apis"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      layout: {
        labelCol: { span: 5 }, //标签占5份  共24份
        wrapperCol: { span: 19 },
      },
      params: {
        returnId: undefined,
        returnReason: undefined,
      },
    }
  },
  methods: {
    show(data) {
      this.params.returnId = data.returnId
      this.showPopup = true
    },
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.returnReason) return this.$message.warning('请输入备注')
      this.$loading.show()
      const res = await postSellerNotCanSendProductApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.info('修改成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        returnId: undefined,
        returnReason: undefined,
      }
      this.showPopup = false
    }
  }
}
</script>
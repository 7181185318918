import {objArrMapObj} from "@/untils/otherEvent"

/** 退款订单列表 */
export const orderReturnTableColumns = [
    { title: "标记", align: "center", scopedSlots: {customRender: 'itemActionTagSlot'}, width: 150 },
    { title: "订单号", align: "center", scopedSlots: {customRender: 'itemOrderNoSlot'}, width: 230 },
    { title: "产品名称", align: "center", scopedSlots: {customRender: 'itemProductNameSlot' }, width: 150 },
    { title: "产品快照", align: "center", scopedSlots: {customRender: 'itemProductImgListSlot' }, width: 220 },
    { title: "金额", align: "center", scopedSlots: {customRender: 'itemOrderPriceAndPayTypeSlot' }, width: 150 },
    { title: "订单状态", align: "center", scopedSlots: {customRender: 'itemOrderStatusSlot' }, width: 300 },
    { title: "买/卖家信息", align: "center", scopedSlots: {customRender: 'itemBuyerInfoOrSellerInfoSlot' }, width: 350 },
    { title: "申请时间", align: "center", dataIndex: 'returnSubmitTime', width: 100 },
    { title: "客服介入", align: "center", scopedSlots: {customRender: 'itemServiceJoinStatusSlot'}, width: 100 },
    { title: "操作相关", align: "center", scopedSlots: {customRender: 'itemActionSlot'}, width: 200 }
]

/** 退款状态 */
export const returnPriceOrderStatusList = [
    { name: "退款申请", value: "apply" },
    { name: "退款申请被拒绝", value: "refuse" },
    { name: "等待买家退货", value: "waitReturn" },
    { name: "等待商家确认收货", value: "waitConfirm" },
    { name: "拒绝确认收货", value: "refuseConfirm" },
    { name: "退款取消", value: "cancel" },
    { name: "退款完成", value: "confirm" },
]

export const returnPriceOrderStatusListMapText = (value) => {
    return objArrMapObj('value', 'name', returnPriceOrderStatusList)[value]
}

export const returnPriceStatusClassType = {
    apply: 'color-black',
    refuse: 'color-red',
    waitReturn: 'color-gray',
    waitConfirm: 'color-gray',
    refuseConfirm: 'color-red',
    cancel: 'color-gray',
    confirm: 'color-gray',
}

/** 客服是否介入 */
export const serviceJoinTypeList = [
    { name: "需要介入", value: 1 },
    { name: "无需介入", value: 0 },
]

export const serviceJoinTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', serviceJoinTypeList)[value]
}

export const serviceJoinTypeClassType = {
    0: 'color-black',
    1: 'color-red',
}

/** 是否承担运费 */
export const logisticsPriceStatusList = [
    { name: "无需承担运费", value: 0 },
    { name: "需要承担运费", value: 1 },
]

/** 处理状态 */
export const serviceActionOverStatusList = [
    { name: "已处理", value: 1 },
    { name: "未处理", value: 0 },
]
export const serviceActionOverStatusClassType = {
    1: 'color-green',
    0: 'color-red',
}